import { $fetch, type FetchOptions } from 'ofetch';

import BooksModule from '@/repository/books';
import NewsModule from '~/repository/news';

interface ApiInstance {
    // TODO: ADD THIS
    books: BooksModule;
    news: NewsModule;
}

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    const fetchOptions: FetchOptions = {
        baseURL: config.public.apiBaseUrl as string
    };

    const apiFecther = $fetch.create(fetchOptions);

    const modules: ApiInstance = {
        // TODO: ADD THIS
        books: new BooksModule(apiFecther),
        news: new NewsModule(apiFecther),
    };

    return {
        provide: {
            api: modules
        }
    };
});