import type { $Fetch, FetchOptions } from 'ofetch';
import type { AsyncDataOptions } from '#app';

import FetchFactory from '../shared/ofetch-factory';
import type { RuntimeConfig } from 'nuxt/schema';

export type News = {
    meta: {};
    data: {
        id: number,
        book_name: string
    }[];
    message: {};
}

class NewsModule extends FetchFactory<News> {
    private RESOURCE = '/';
    private config: RuntimeConfig;

    constructor(fetcher: $Fetch) {
        super(fetcher)
        this.config = useRuntimeConfig();
    }

    /**
     * Return the products as array 
     * @param asyncDataOptions options for `useAsyncData`
     * @returns 
     */
    async getNewsMedium(
        url?: string,
        asyncDataOptions?: AsyncDataOptions<News>
    ) {
        return useAsyncData(
            () => {
                const fetchOptions: FetchOptions<'json'> = {
                    headers: {
                        // 'Authorization': this.config.public.apiKey as string,
                        // 'x-api-key': this.config.public.apiKey as string,
                        // 'Accept': "application/rss+xml" as string
                    },
                };
                return this.call(
                    'GET',
                    `${url}`,
                    undefined, // body
                    fetchOptions,
                )
            },
            asyncDataOptions
        )
    }
}

export default NewsModule;