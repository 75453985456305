import type { $Fetch, FetchOptions } from 'ofetch';
import type { AsyncDataOptions } from '#app';

import FetchFactory from '../shared/ofetch-factory';
import type { RuntimeConfig } from 'nuxt/schema';

export type Books = {
    meta: {};
    data: {
        id: number,
        book_name: string
    }[];
    message: {};
}

class BooksModule extends FetchFactory<Books> {
    private RESOURCE = '/api/v1/books';
    private config: RuntimeConfig;

    constructor(fetcher: $Fetch) {
        super(fetcher)
        this.config = useRuntimeConfig();
    }

    /**
     * Return the products as array 
     * @param asyncDataOptions options for `useAsyncData`
     * @returns 
     */
    async getBook(
        asyncDataOptions?: AsyncDataOptions<Books>
    ) {
        return useAsyncData(
            () => {
                const fetchOptions: FetchOptions<'json'> = {
                    headers: {
                        'Authorization': this.config.public.apiKey as string,
                        'x-api-key': this.config.public.apiKey as string
                    },
                };
                return this.call(
                    'GET',
                    `${this.RESOURCE}`,
                    undefined, // body
                    fetchOptions,
                )
            },
            asyncDataOptions
        )
    }
}

export default BooksModule;