import validate from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/validate.js";
import always_45run_45global from "/vercel/path1/middleware/always-run.global.ts";
import manifest_45route_45rule from "/vercel/path1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  always_45run_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-me": () => import("/vercel/path1/middleware/redirect-me.ts")
}