import { default as indexg6HQYIfHEqMeta } from "/vercel/path1/pages/about/index.vue?macro=true";
import { default as indexl3ZcjxuvDXMeta } from "/vercel/path1/pages/album/index.vue?macro=true";
import { default as indexHztpQjb2YtMeta } from "/vercel/path1/pages/contact/index.vue?macro=true";
import { default as indexFfStcQiQRAMeta } from "/vercel/path1/pages/forbidden/index.vue?macro=true";
import { default as index_45exn0bcxAUZ9NMeta } from "/vercel/path1/pages/index/index-ex.vue?macro=true";
import { default as indexupcjcY82K1Meta } from "/vercel/path1/pages/index/index.vue?macro=true";
import { default as indexDuPcphDkoOMeta } from "/vercel/path1/pages/news/index.vue?macro=true";
import { default as indexKhyKbpZlMQMeta } from "/vercel/path1/pages/portofolio/index.vue?macro=true";
import { default as detaila71NbMxAyGMeta } from "/vercel/path1/pages/timeline/detail.vue?macro=true";
import { default as indexsUoTMFBQgsMeta } from "/vercel/path1/pages/timeline/index.vue?macro=true";
export default [
  {
    name: indexg6HQYIfHEqMeta?.name ?? "about___en",
    path: indexg6HQYIfHEqMeta?.path ?? "/en/about",
    meta: indexg6HQYIfHEqMeta || {},
    alias: indexg6HQYIfHEqMeta?.alias || [],
    redirect: indexg6HQYIfHEqMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexg6HQYIfHEqMeta?.name ?? "about___id",
    path: indexg6HQYIfHEqMeta?.path ?? "/about",
    meta: indexg6HQYIfHEqMeta || {},
    alias: indexg6HQYIfHEqMeta?.alias || [],
    redirect: indexg6HQYIfHEqMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexl3ZcjxuvDXMeta?.name ?? "album___en",
    path: indexl3ZcjxuvDXMeta?.path ?? "/en/album",
    meta: indexl3ZcjxuvDXMeta || {},
    alias: indexl3ZcjxuvDXMeta?.alias || [],
    redirect: indexl3ZcjxuvDXMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/album/index.vue").then(m => m.default || m)
  },
  {
    name: indexl3ZcjxuvDXMeta?.name ?? "album___id",
    path: indexl3ZcjxuvDXMeta?.path ?? "/album",
    meta: indexl3ZcjxuvDXMeta || {},
    alias: indexl3ZcjxuvDXMeta?.alias || [],
    redirect: indexl3ZcjxuvDXMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/album/index.vue").then(m => m.default || m)
  },
  {
    name: indexHztpQjb2YtMeta?.name ?? "contact___en",
    path: indexHztpQjb2YtMeta?.path ?? "/en/contact",
    meta: indexHztpQjb2YtMeta || {},
    alias: indexHztpQjb2YtMeta?.alias || [],
    redirect: indexHztpQjb2YtMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexHztpQjb2YtMeta?.name ?? "contact___id",
    path: indexHztpQjb2YtMeta?.path ?? "/contact",
    meta: indexHztpQjb2YtMeta || {},
    alias: indexHztpQjb2YtMeta?.alias || [],
    redirect: indexHztpQjb2YtMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexFfStcQiQRAMeta?.name ?? "forbidden___en",
    path: indexFfStcQiQRAMeta?.path ?? "/en/forbidden",
    meta: indexFfStcQiQRAMeta || {},
    alias: indexFfStcQiQRAMeta?.alias || [],
    redirect: indexFfStcQiQRAMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: indexFfStcQiQRAMeta?.name ?? "forbidden___id",
    path: indexFfStcQiQRAMeta?.path ?? "/forbidden",
    meta: indexFfStcQiQRAMeta || {},
    alias: indexFfStcQiQRAMeta?.alias || [],
    redirect: indexFfStcQiQRAMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: index_45exn0bcxAUZ9NMeta?.name ?? "index-index-ex___en",
    path: index_45exn0bcxAUZ9NMeta?.path ?? "/en//index-ex",
    meta: index_45exn0bcxAUZ9NMeta || {},
    alias: index_45exn0bcxAUZ9NMeta?.alias || [],
    redirect: index_45exn0bcxAUZ9NMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/index/index-ex.vue").then(m => m.default || m)
  },
  {
    name: index_45exn0bcxAUZ9NMeta?.name ?? "index-index-ex___id",
    path: index_45exn0bcxAUZ9NMeta?.path ?? "//index-ex",
    meta: index_45exn0bcxAUZ9NMeta || {},
    alias: index_45exn0bcxAUZ9NMeta?.alias || [],
    redirect: index_45exn0bcxAUZ9NMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/index/index-ex.vue").then(m => m.default || m)
  },
  {
    name: indexupcjcY82K1Meta?.name ?? "index___en",
    path: indexupcjcY82K1Meta?.path ?? "/en",
    meta: indexupcjcY82K1Meta || {},
    alias: indexupcjcY82K1Meta?.alias || [],
    redirect: indexupcjcY82K1Meta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexupcjcY82K1Meta?.name ?? "index___id",
    path: indexupcjcY82K1Meta?.path ?? "/",
    meta: indexupcjcY82K1Meta || {},
    alias: indexupcjcY82K1Meta?.alias || [],
    redirect: indexupcjcY82K1Meta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexDuPcphDkoOMeta?.name ?? "news___en",
    path: indexDuPcphDkoOMeta?.path ?? "/en/news",
    meta: indexDuPcphDkoOMeta || {},
    alias: indexDuPcphDkoOMeta?.alias || [],
    redirect: indexDuPcphDkoOMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexDuPcphDkoOMeta?.name ?? "news___id",
    path: indexDuPcphDkoOMeta?.path ?? "/news",
    meta: indexDuPcphDkoOMeta || {},
    alias: indexDuPcphDkoOMeta?.alias || [],
    redirect: indexDuPcphDkoOMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexKhyKbpZlMQMeta?.name ?? "portofolio___en",
    path: indexKhyKbpZlMQMeta?.path ?? "/en/portofolio",
    meta: indexKhyKbpZlMQMeta || {},
    alias: indexKhyKbpZlMQMeta?.alias || [],
    redirect: indexKhyKbpZlMQMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/portofolio/index.vue").then(m => m.default || m)
  },
  {
    name: indexKhyKbpZlMQMeta?.name ?? "portofolio___id",
    path: indexKhyKbpZlMQMeta?.path ?? "/portofolio",
    meta: indexKhyKbpZlMQMeta || {},
    alias: indexKhyKbpZlMQMeta?.alias || [],
    redirect: indexKhyKbpZlMQMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/portofolio/index.vue").then(m => m.default || m)
  },
  {
    name: detaila71NbMxAyGMeta?.name ?? "timeline-detail___en",
    path: detaila71NbMxAyGMeta?.path ?? "/en/timeline/detail",
    meta: detaila71NbMxAyGMeta || {},
    alias: detaila71NbMxAyGMeta?.alias || [],
    redirect: detaila71NbMxAyGMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/timeline/detail.vue").then(m => m.default || m)
  },
  {
    name: detaila71NbMxAyGMeta?.name ?? "timeline-detail___id",
    path: detaila71NbMxAyGMeta?.path ?? "/timeline/detail",
    meta: detaila71NbMxAyGMeta || {},
    alias: detaila71NbMxAyGMeta?.alias || [],
    redirect: detaila71NbMxAyGMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/timeline/detail.vue").then(m => m.default || m)
  },
  {
    name: indexsUoTMFBQgsMeta?.name ?? "timeline___en",
    path: indexsUoTMFBQgsMeta?.path ?? "/en/timeline",
    meta: indexsUoTMFBQgsMeta || {},
    alias: indexsUoTMFBQgsMeta?.alias || [],
    redirect: indexsUoTMFBQgsMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: indexsUoTMFBQgsMeta?.name ?? "timeline___id",
    path: indexsUoTMFBQgsMeta?.path ?? "/timeline",
    meta: indexsUoTMFBQgsMeta || {},
    alias: indexsUoTMFBQgsMeta?.alias || [],
    redirect: indexsUoTMFBQgsMeta?.redirect || undefined,
    component: () => import("/vercel/path1/pages/timeline/index.vue").then(m => m.default || m)
  }
]